<template>
  <CContainer class="d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">
            500
          </h1>
          <h4 class="pt-3">
            Houston, tenemos un problema
          </h4>
          <p class="text-muted">
            La página que estás buscando está temporalmente no funcional.
          </p>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

export default {
  name: "Page500",
};
</script>
